<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>參數設定</b-breadcrumb-item>
            <b-breadcrumb-item active>會員介面設定</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">會員介面設定</h4>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group
              v-for="(i, idx) in colorMap"
              :key="idx"
              label-cols="12"
              label-cols-lg="4"
              label-size="sm"
              :label="i.text"
              label-class="font-weight-bold text-lg-right"
            >
              <color-picker v-model="setColorObject[i.key]"></color-picker>
              <div>{{ i.name }}</div>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <UIReview
              v-if="moduleConfig"
              :moduleConfig="moduleConfig"
              :tabs="tabs"
              :colorObject="setColorObject"
            ></UIReview>
          </b-col>
        </b-row>
      </b-overlay>

      <div class="d-flex justify-content-center mt-3">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel"
          >取消</b-button
        >
        <b-overlay
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            class="mr-2"
            variant="success"
            @click="handleSubmit(false)"
            :disabled="isLoading"
          >
            儲存
          </b-button>
          <b-button
            variant="success"
            @click="handleSubmit"
            :disabled="isLoading"
          >
            儲存後關閉
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
//import _ from "lodash";

import UIReview from "@/components/Dashboard/LiffUI/UIReview";
import moduleMap from "@/mixins/liff/memberCenter/moduleMap";
import themeColor from "@/mixins/liff/themeColor";
import ColorPicker from "@/components/ColorPicker";
import liffSettingApi from "@/apis/liff-setting";

const colorMap = [
  {
    name: "primary_text_color",
    key: "--liff-primary_text_color",
    text: "主字色",
  },
  {
    name: "secondary_text_color",
    key: "--liff-secondary_text_color",
    text: "第二字色",
  },
  {
    name: "primary_color",
    key: "--primary-color",
    text: "強調字色",
  },
  {
    name: "header_color",
    key: "--liff-header-color",
    text: "表頭底色",
  },
  {
    name: "header_text_color",
    key: "--liff-header-text-color",
    text: "表頭字色",
  },
  {
    name: "button_color",
    key: "--liff-button-color",
    text: "按鈕底色",
  },
  {
    name: "button_text_color",
    key: "--liff-button-text-color",
    text: "按鈕字色",
  },
  {
    name: "tab_inactive_color",
    key: "--liff-tab_inactive_color",
    text: "頁籤色(未選取)",
  },
  {
    name: "tab_color",
    key: "--liff-tab-color",
    text: "頁籤底線&字色",
  },

  {
    name: "content_bg_color",
    key: "--liff-content_bg_color",
    text: "頁面內容底色",
  },

  {
    name: "layout_bg_color",
    key: "--liff-layout_bg_color",
    text: "整體背景底色",
  },
];

export default {
  mixins: [moduleMap, themeColor],
  components: {
    UIReview,
    "color-picker": ColorPicker,
  },

  //components: {editor, 'color-picker': ColorPicker},
  validationConfig: {
    $lazy: true,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      themeConfigPage: "coupon",
      moduleConfig: {},
      isLoading: false,
      isSubmmiting: false,
      tabs: [],
      setColorObject: null,
      colorMap,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  validations() {
    let rules = {};

    return rules;
  },
  async mounted() {
    await this.$store.dispatch("liffGeneral/init", this.$route.params.org_code);
    await this.$store.dispatch("liffModule/fetchModule", "liff_coupon");

    this.moduleConfig =
      this.$store.getters["liffModule/getModule"]("liff_coupon")
        .module_config || {};
    this.tabs = this.getModuleConfig("data_source");
    this.setColorObject = this.colorObject;
  },
  methods: {
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("liff_coupon", key);
    },
    handleCancel() {
      this.$router.go(-1);
    },
    async handleSubmit(redirect = true) {
      const result = await this.v$.$validate();
      if (!result) return;
      let params = {};
      for (let i = 0; i < this.colorMap.length; i++) {
        params[this.colorMap[i].name] =
          this.setColorObject[this.colorMap[i].key];
      }
      const response = await liffSettingApi.updateUISetting(params);

      if (response.status && response.status === 200) {
        this.$swal
          .fire({
            title: "更新成功",
            type: "success",
          })
          .then(() => {
            if (redirect) {
              this.$router.go(-1);
            }
          });
      }
    },
  },
};
</script>
